/* css minification
Editor: Julius Fourtech */
body {
  font-family: "Open Sans", sans-serif;
  color: #444;
}
a {
  text-decoration: none;
  color: #0880e8;
}
a:hover {
  color: #2b99f8;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Raleway, sans-serif;
}
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #0880e8;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}
.free-education {
  padding: 10px;
}
.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #2194f7;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}
#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #0880e8;
  border-top-color: #bfe0fd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}
@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-preloader {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
  [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1 !important;
  }
  [data-aos="fade-up"] {
    transform: none !important;
  }
}
#topbar {
  height: 60px;
  padding: 0;
  font-size: 14px;
  transition: all 0.5s;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);
  z-index: 996;
}
#topbar.topbar-scrolled {
  top: -60px;
}
#topbar .contact-info a {
  line-height: 0;
  color: rgba(255, 255, 255, 0.8);
  transition: 0.3s;
}
#topbar .contact-info a:hover {
  text-decoration: underline;
}
#topbar .contact-info i {
  color: #f6b024;
  line-height: 0;
  margin-right: 5px;
}
#topbar .contact-info .phone-icon {
  margin-left: 15px;
}
#topbar .cta {
  background: 0 0;
}
#topbar .cta a {
  color: #fff;
  background: #f6b024;
  padding: 6px 24px 8px 24px;
  display: inline-block;
  transition: 0.3s;
  border-radius: 50px;
}
#topbar .cta a:hover {
  background: #f1a40a;
}
#header {
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  z-index: 997;
  height: 70px;
  top: 60px;
}
#header.header-scrolled {
  background: rgba(5, 87, 158, 0.9);
  top: 0;
}
#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}
#header .logo a {
  color: #fff;
}
#header .logo img {
  max-height: 60px;
}
.header-inner-pages {
  background: #0b1632 !important;
}
.topbar-inner-pages {
  background: #0b1632 !important;
}
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 10px 0 10px 24px;
}
.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
}
.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #f6b024;
  visibility: hidden;
  width: 0;
  transition: all 0.3s ease-in-out 0s;
}
.navbar .active:before,
.navbar a:hover:before,
.navbar li:hover > a:before {
  visibility: visible;
  width: 100%;
}
.navbar .active,
.navbar .active:focus,
.navbar a:hover,
.navbar li:hover > a {
  color: #fff;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 8px;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #032e54;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul a:hover,
.navbar .dropdown ul li:hover > a {
  color: #0880e8;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
  color: #f6b024;
}
@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(5, 74, 133, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile > ul > li {
  padding: 0;
}
.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #0665b7;
}
.navbar-mobile .active:before,
.navbar-mobile a:hover:before,
.navbar-mobile li:hover > a:before {
  visibility: hidden;
}
.navbar-mobile .active,
.navbar-mobile a:hover,
.navbar-mobile li:hover > a {
  color: #f6b024;
}
.navbar-mobile .getstarted {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #f6b024;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}
#hero {
  width: 100%;
  height: 130vh;
  overflow: hidden;
  position: relative;
  margin-bottom: -130px;
  transition: 0.3s;
}
#hero:before {
  content: "";
  background: #0b1632;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero .carousel {
  margin-top: 375px;
}
#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.carousel-caption {
  height: 80%;
}
#hero img {
  filter: brightness(90%);
}
.carousel {
  width: 100%;
}
#hero h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}
#hero p {
  width: 80%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}
#hero .carousel-control-next,
#hero .carousel-control-prev {
  width: 10%;
}
#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: 0 0;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}
.carousel-item {
  height: 130vh !important;
}
.btn-get-started {
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #0b1632;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #f6b024;
}
#hero .btn-get-started:hover {
  background: #f6b024;
  color: #fff;
  text-decoration: none;
}
@media (min-width: 1024px) {
  #hero p {
    width: 60%;
  }
  #hero .carousel-control-next,
  #hero .carousel-control-prev {
    width: 15%;
  }
}
@media (max-width: 768px), (max-height: 700px) {
  #hero {
    height: 130vh;
    padding: 100px 0;
  }
  #hero h2 {
    font-size: 28px;
  }
  #hero p {
    width: 100%;
    font-size: 21px;
  }
  #hero .carousel-container {
    margin-top: -3rem;
  }
  #hero .responsive {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
  #hero .hero-heading {
    font-size: 49px;
    font-weight: 900;
  }
}
@media (min-width: 769px) {
  #hero .carousel-container {
    margin-top: 3rem;
  }
}
section {
  padding: 15px 0;
  overflow: hidden;
}
.login-area {
  margin-top: 130px;
  padding-top: 55px;
  padding-bottom: 50px;
  background-color: #ecf6fe;
}
.loading-area {
  margin-top: 15rem;
  height: 200px;
}
.error-area {
  margin-top: 15rem;
  margin-bottom: 5rem;
}
.contact,
.portfoio,
.team {
  padding: 50px 0;
}
.section-bg {
  background-color: #f1f8ff;
}
.section-title {
  text-align: center;
  padding-bottom: 10px;
}
.section-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-bottom: 0;
  color: #054a85;
}
.section-title p {
  margin-bottom: 0;
  font-style: italic;
}
.icon-boxes {
  padding-top: 0;
  position: relative;
  z-index: 100;
}
.icon-boxes .icon-box {
  padding: 40px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 5px 10px 29px 0 rgba(68, 88, 144, 0.2);
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}
.icon-boxes .icon {
  margin: 0 auto 20px auto;
  display: inline-block;
  text-align: center;
}
.icon-boxes .icon i {
  font-size: 36px;
  line-height: 1;
  color: #f6b024;
}
.icon-boxes .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}
.icon-boxes .title a {
  color: #05579e;
}
.icon-boxes .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
  color: #777;
}
.about {
  padding: 60px 0;
  background: url("../src/img/about-bg.png") top center no-repeat;
  position: relative;
}
.about:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.about .container {
  position: relative;
}
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}
.about .content ul {
  list-style: none;
  padding: 0;
}
.about .content ul li {
  padding-left: 28px;
  position: relative;
}
.about .content ul li + li {
  margin-top: 10px;
}
.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #0880e8;
  line-height: 1;
}
.about .content p:last-child {
  margin-bottom: 0;
}
.about .content .btn-learn-more {
  font-family: Raleway, sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.3s;
  line-height: 1;
  color: #0880e8;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #0880e8;
}
.about .content .btn-learn-more:hover {
  background: #0880e8;
  color: #fff;
  text-decoration: none;
}
.about-img {
  height: 500px;
}
@media (max-width: 992px) {
  .about {
    padding: 60px 0;
  }
}
@media (max-width: 500px) {
  .about-img {
    height: 450px;
  }
}
.clients .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
  width: 50%;
  height: 50%;
}
.clients .swiper-slide:hover img {
  opacity: 1;
}
.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #0880e8;
}
.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #0880e8;
}
.clients .owl-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
.clients .owl-item img {
  width: 60%;
  opacity: 0.5;
  transition: 0.3s;
}
.clients .owl-item img:hover {
  opacity: 1;
}
.clients .owl-dots,
.clients .owl-nav {
  margin-top: 5px;
  text-align: center;
}
.clients .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}
.clients .owl-dot.active {
  background-color: #0880e8 !important;
}
.why-us {
  background: #f1f8ff;
  padding: 0;
}
.why-us .content {
  padding: 60px 100px 0 100px;
}
.why-us .content h3 {
  font-weight: 400;
  font-size: 34px;
}
.why-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}
.why-us .content p {
  font-size: 15px;
  color: #848484;
}
.why-us .video-box {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
  position: relative;
}
.why-us .accordion-list {
  padding: 0 100px 60px 100px;
}
.why-us .accordion-list ul {
  padding: 0;
  list-style: none;
}
.why-us .accordion-list li + li {
  margin-top: 15px;
}
.why-us .accordion-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}
.why-us .accordion-list a {
  display: block;
  position: relative;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: 0;
  cursor: pointer;
}
.why-us .accordion-list span {
  color: #0880e8;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}
.why-us .accordion-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}
.why-us .accordion-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}
.why-us .accordion-list .icon-show {
  display: none;
}
.why-us .accordion-list a.collapsed {
  color: #343a40;
}
.why-us .accordion-list a.collapsed:hover {
  color: #0880e8;
}
.why-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}
.why-us .accordion-list a.collapsed .icon-close {
  display: none;
}
.why-us .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#0880e8 50%, rgba(8, 128, 232, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}
.why-us .play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 0.4s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.why-us .play-btn::before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(8, 128, 232, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}
.why-us .play-btn:hover::after {
  border-left: 15px solid #0880e8;
  transform: scale(20);
}
.why-us .play-btn:hover::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}
@media (max-width: 1024px) {
  .why-us .accordion-list,
  .why-us .content {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 992px) {
  .why-us .content {
    padding-top: 30px;
  }
  .why-us .accordion-list {
    padding-bottom: 30px;
  }
}
@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
.services {
  background: #f1f8ff;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 50px 40px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}
.services .icon-box i {
  float: left;
  color: #f6b024;
  font-size: 40px;
  line-height: 0;
}
.services .icon-box h4 {
  margin-left: 70px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}
.services .icon-box h4 a {
  color: #05579e;
  transition: 0.3s;
}
.services .icon-box h4 a:hover {
  color: #0880e8;
}
.services .icon-box p {
  margin-left: 70px;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.cta {
  background: linear-gradient(rgba(5, 74, 133, 0.8), rgba(5, 74, 133, 0.9)),
    url("../src/img/cta-bg.jpg") fixed center center;
  background-size: cover;
  padding: 120px 0;
}
.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}
.cta p {
  color: #fff;
}
.cta .cta-btn {
  font-family: Raleway, sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  display: inline-block;
  padding: 8px 26px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
  border-radius: 50px;
  border: 2px solid #f6b024;
  color: #fff;
}
.cta .cta-btn:hover {
  background: #f6b024;
}
@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}
@media (min-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.portfoio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}
.portfoio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 18px 10px 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #444;
  margin: 0 3px 10px 3px;
  transition: all ease-in-out 0.3s;
  border-radius: 50px;
}
.portfoio #portfolio-flters li.filter-active,
.portfoio #portfolio-flters li:hover {
  color: #fff;
  background: #0880e8;
}
.portfoio #portfolio-flters li:last-child {
  margin-right: 0;
}
.portfoio .portfolio-item {
  margin-bottom: 30px;
  overflow: hidden;
}
.portfoio .portfolio-item img {
  position: relative;
  top: 0;
  transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.portfoio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: -50px;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: #0880e8;
  padding: 15px 20px;
}
.portfoio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
.portfoio .portfolio-item .portfolio-info p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}
.portfoio .portfolio-item .portfolio-info .details-link,
.portfoio .portfolio-item .portfolio-info .preview-link {
  position: absolute;
  right: 50px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #fff;
  transition: ease-in-out 0.3s;
}
.portfoio .portfolio-item .portfolio-info .details-link:hover,
.portfoio .portfolio-item .portfolio-info .preview-link:hover {
  color: #8ec8fb;
}
.portfoio .portfolio-item .portfolio-info .details-link {
  right: 15px;
}
.portfoio .portfolio-item:hover img {
  top: -30px;
}
.portfoio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 0;
}
.portfolio-details {
  padding-top: 40px;
}
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}
.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #0880e8;
}
.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #0880e8;
}
.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0 0 30px rgba(246, 176, 36, 0.08);
}
.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}
.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}
.portfolio-details .portfolio-description {
  padding-top: 30px;
}
.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.portfolio-details .portfolio-description p {
  padding: 0;
}
.team .member {
  position: relative;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
  background: #fff;
}
.team .team-member {
  position: relative;
  padding: 30px;
  border-radius: 10px;
}
.team .podcast_pic {
  overflow: hidden;
  width: 40%;
}
.book_pic {
  overflow: hidden;
  width: 80%;
}
/* .team .member .pic img,
.team-member .pic img {
  border-radius: 50%;
  width: 30vh;
  object-fit: contain;
  height: 30vh;
  margin-bottom: 10px;
  transition: ease-in-out 0.3s;
  height: 10em;
  width: 10em;
} */



@media screen and (max-width: 768px) {
  .team .member .pic img,
  .team-member .pic img {
    height: 7.5em;
    width: 7.5em;
  }
}
.team .member:hover img {
  transform: scale(1.1);
}
.team .member .member-info {
  padding-left: 30px;
}
.team .member h4,
.team-member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #05579e;
}
.about-separator {
  margin: 5px 60px;
  height: 1px;
  background: #bfe0fd;
}
.team .member span,
.team-member span {
  font-size: 15px;
  padding-bottom: 10px;
  font-weight: 600;
}
.team .member p,
.team-member p {
  margin: 10px 0 0 0;
  font-size: 14px;
  text-align: justify;
}
.team .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.team .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  background: #ecf6fe;
  color: #0665b7;
}
.team .member .social {
  width: 50px;
}
.team .member .social a i {
  font-size: 16px;
  margin: 0 2px;
}
.team .player {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 38px;
  height: 38px;
  background: #ecf6fe;
  color: #0665b7;
  cursor: pointer;
}
.team .player i:hover {
  transition: ease-in-out 0.3s;
  font-size: 30px;
}
.team .player i {
  font-size: 25px;
  margin: 3px 2px;
}
.team .member .social a:hover {
  background: #0880e8;
  color: #fff;
}
.team .member .social a + a {
  margin-left: 8px;
}
.bg-light-blue {
  background-color: #f6fbff !important;
}
.pricing .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}
.pricing h3 {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 18px;
  font-weight: 700;
  color: #05579e;
  background: #f8f8f8;
}
.pricing h4 {
  font-size: 36px;
  color: #0880e8;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  margin-bottom: 20px;
}
.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}
.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}
.pricing ul {
  padding: 0;
  list-style: none;
  color: #444;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}
.pricing ul li {
  padding-bottom: 16px;
}
.pricing ul i {
  color: #0880e8;
  font-size: 18px;
  padding-right: 4px;
}
.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}
.pricing .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #f8f8f8;
  text-align: center;
}
.pricing .btn-buy {
  background: #0880e8;
  display: inline-block;
  padding: 8px 35px 10px 35px;
  border-radius: 50px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  transition: 0.3s;
}
.pricing .btn-buy:hover {
  background: #2b99f8;
}
.pricing .featured h3 {
  color: #fff;
  background: #0880e8;
}
.pricing .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #0880e8;
  color: #fff;
}
.faq .faq-list {
  padding: 0 100px;
}
.faq .faq-list ul {
  padding: 0;
  list-style: none;
}
.faq .faq-list li + li {
  margin-top: 15px;
}
.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}
.faq .faq-list a {
  display: block;
  position: relative;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: 0;
  cursor: pointer;
}
.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #75bdfa;
}
.faq .faq-list .icon-close,
.faq .faq-list .icon-show {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}
.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}
.faq .faq-list .icon-show {
  display: none;
}
.faq .faq-list a.collapsed {
  color: #343a40;
}
.faq .faq-list a.collapsed:hover {
  color: #0880e8;
}
.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}
.faq .faq-list a.collapsed .icon-close {
  display: none;
}
@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}
.contact {
  background: url("../src/img/contact-bg.png") left center no-repeat;
  position: relative;
}
.contact:before {
  content: "";
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.contact .container {
  position: relative;
}
.contact .info {
  width: 100%;
}
.contact .info i {
  font-size: 20px;
  background: #0880e8;
  color: #fff;
  float: left;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #043c6d;
}
.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #444;
}
.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}
.contact .contact-form {
  width: 100%;
  background: #fff;
}
.contact .contact-form .form-group {
  padding-bottom: 8px;
}
.contact .contact-form .error-message {
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .contact-form .error-message br + br {
  margin-top: 25px;
}
.contact .contact-form .sent-message {
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .contact-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .contact-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.contact .contact-form input,
.contact .contact-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
}
.contact .contact-form input {
  height: 44px;
}
.contact .contact-form textarea {
  padding: 10px 12px;
}
.contact .contact-form button[type="submit"] {
  border: 0;
  padding: 10px 32px;
  color: #0880e8;
  transition: 0.4s;
  border-radius: 50px;
  border: 2px solid #0880e8;
  background: #fff;
}
.contact .contact-form button[type="submit"]:hover {
  background: #0880e8;
  color: #fff;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.breadcrumbs {
  padding: 15px 0;
  background: #ecf6fe;
  margin-top: 130px;
}
.breadcrumbs h2 {
  font-size: 26px;
  font-weight: 600;
  color: #043c6d;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #f8c255;
  content: "/";
}
.blog {
  padding: 40px 0 20px 0;
}
.blog .entry {
  padding: 30px;
  margin-bottom: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .entry .entry-img img {
  width: 100%;
  max-height: 440px;
  object-fit: cover;
}
.blog .entry .entry-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 10px 0;
}
.blog .entry .entry-list-title {
  font-size: 17px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 10px 0;
}
.blog .entry .entry-title a,
.entry-title {
  color: #054a85;
  transition: 0.3s;
}
.blog .entry .entry-list-title a:hover,
.blog .entry .entry-title a:hover {
  color: #f6b024;
}
.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #2b99f8;
}
.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}
.blog .entry .entry-meta ul li + li {
  padding-left: 20px;
}
.blog .entry .entry-meta i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
}
.blog .entry .entry-meta a {
  color: #777;
  font-size: 14px;
  display: inline-block;
  line-height: 0;
}
.blog .entry .entry-content p {
  line-height: 24px;
  white-space: pre-line;
}
.blog .entry .entry-content-list p {
  margin-top: -10px;
  line-height: 24px;
}
.font-15 {
  font-size: 15px;
}
.blog .entry .entry-content-list .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}
.blog .entry .entry-content-list .read-more a {
  display: inline-block;
  background: #f6b024;
  color: #fff;
  padding: 6px 20px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}
.blog .entry .entry-content .read-more a:hover {
  background: #f7b93c;
}
.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: 700;
}
.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}
.blog .entry .entry-content blockquote p {
  color: #444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}
.blog .entry .entry-content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #0880e8;
  margin-top: 20px;
  margin-bottom: 20px;
}
.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}
.blog .entry .entry-footer i {
  color: #8ec8fb;
  display: inline;
}
.blog .entry .entry-footer a {
  color: #138df7;
  transition: 0.3s;
}
.blog .entry .entry-footer a:hover {
  color: #f6b024;
}
.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}
.blog .entry .entry-footer .cats li {
  display: inline-block;
}
.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}
.blog .entry .entry-footer .tags li {
  display: inline-block;
}
.blog .entry .entry-footer .tags li + li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}
.blog .entry .entry-footer .share {
  font-size: 16px;
}
.blog .entry .entry-footer .share i {
  padding-left: 5px;
}
.blog .entry-single {
  margin-bottom: 30px;
}
.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .blog-author img {
  width: 120px;
  margin-right: 20px;
}
.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0;
  padding: 0;
  color: #0880e8;
}
.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}
.blog .blog-author .social-links a {
  color: rgba(8, 128, 232, 0.5);
  margin-right: 5px;
}
.blog .blog-author p {
  font-style: italic;
  color: #b7b7b7;
}
.blog .blog-comments {
  margin-bottom: 30px;
}
.blog .blog-comments .comments-count {
  font-weight: 700;
}
.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}
.blog .blog-comments .comment .comment-img {
  margin-right: 14px;
}
.blog .blog-comments .comment .comment-img img {
  width: 60px;
}
.blog .blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}
.blog .blog-comments .comment h5 a {
  font-weight: 700;
  color: #444;
  transition: 0.3s;
}
.blog .blog-comments .comment h5 a:hover {
  color: #f6b024;
}
.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #0880e8;
}
.blog .blog-comments .comment h5 .reply i {
  font-size: 20px;
}
.blog .blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: #2b99f8;
  margin-bottom: 5px;
}
.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}
.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .blog-comments .reply-form h4 {
  font-weight: 700;
  font-size: 22px;
}
.blog .blog-comments .reply-form p {
  font-size: 14px;
}
.blog .blog-comments .reply-form input {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}
.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #fad386;
}
.blog .blog-comments .reply-form textarea {
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
}
.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #fad386;
}
.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}
.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: #0880e8;
}
.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #138df7;
}
.blog-pagination {
  color: #0880e8;
}
.blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}
.blog-pagination li span {
  color: #0880e8;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blog-pagination li.active,
.blog-pagination li:hover {
  background: #f6b024;
  cursor: pointer;
}
.blog-pagination li.active a,
.blog-pagination li:hover a {
  color: #fff;
}
.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 15px 0;
  color: #0665b7;
  position: relative;
}
.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}
.search-form form {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  position: relative;
  width: 50%;
}
.search-form form input[type="text"] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
}
.search-form input:focus {
  outline: 0;
}
.search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: 0 0;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #f6b024;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}
.search-form form button i {
  line-height: 0;
}
.search-form form button:hover {
  background: #f8c255;
}
@media (max-width: 469px) {
  .search-form form {
    width: 100%;
  }
}
.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}
.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}
.blog .sidebar .categories ul a {
  color: #0665b7;
  transition: 0.3s;
}
.blog .sidebar .categories ul a:hover {
  color: #f6b024;
}
.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #aaa;
  font-size: 14px;
}
.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}
.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}
.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: 700;
}
.blog .sidebar .recent-posts h4 a {
  color: #0665b7;
  transition: 0.3s;
}
.blog .sidebar .recent-posts h4 a:hover {
  color: #f6b024;
}
.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaa;
}
.blog .sidebar .tags {
  margin-bottom: -10px;
}
.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}
.blog .sidebar .tags ul li {
  display: inline-block;
}
.blog .sidebar .tags ul a {
  color: #0880e8;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #fff;
  display: inline-block;
  transition: 0.3s;
}
.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #f6b024;
  background: #f6b024;
}
.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #fff;
  font-size: 14px;
}
#footer {
  background: #0b152f;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}
#footer .footer-newsletter {
  padding: 50px 0;
  background: #0d193e;
}
#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
}
#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
}
#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 100px);
}
#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: 0 0;
  font-size: 16px;
  padding: 0 20px;
  margin: 3px;
  background: #f6b024;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}
#footer .footer-newsletter form input[type="submit"]:hover {
  background: #0880e8;
}
#footer .footer-top {
  background: #0b1632;
  padding: 60px 0 30px 0;
}
#footer .footer-top .footer-info {
  margin-bottom: 30px;
}
#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}
#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: Raleway, sans-serif;
  color: #fff;
}
#footer .footer-top .social-links a {
  font-size: 16px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  line-height: 1;
  padding: 10px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
  background: #f6b024;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #5db1f9;
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: rgba(255, 255, 255, 0.75);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links ul a:hover {
  color: #fff;
}
#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}
#footer .footer-top .footer-contact p {
  line-height: 26px;
}
#footer .copyright {
  text-align: center;
  padding-top: 30px;
}
#footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
#footer .credits a {
  color: #f6b024;
}
.input-group-addon i {
  padding: 11px 11px;
  background-color: #e6e6fa;
  cursor: pointer;
}
.large-margin {
  margin-top: 8rem !important;
}
.preloader-icon {
  display: inline-block;
}
._34Oew {
  z-index: 4 !important;
}
@media (min-width: 1920px) and (max-width: 2560px) {
  #hero {
    height: 105vh;
  }
  .carousel-caption {
    height: 70%;
  }
  .carousel-caption .h1,
  .carousel-caption h1 {
    font-size: 5.5rem;
    text-shadow: -7px 8px 14px #000;
  }
  #hero p {
    font-size: 2.5rem;
    text-shadow: -7px 8px 14px #000;
  }
}
.home-articles-section {
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  height: auto;
}
.home-articles-section h3 {
  text-align: center;
  font-size: 1.6em;
  font-weight: 700;
  color: #000;
}
.home-articles-section-div {
  height: auto;
  padding: 0.5em 1em 0.5em 1em;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-around;
}
.home-article-card {
  height: auto;
  margin: 1em 1.5em 1.5em 1.5em;
  width: 30%;
  border: none;
  border-radius: 9px;
  background: #e8f3fc;
  box-shadow: 28px 28px 94px #bac2ca, -28px -28px 94px #fff;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  color: #000;
}
.card-image-div {
  height: 16em;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: #8fa3b4;
}
.card-image {
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  object-fit: fill;
}
.home-article-card h3 {
  font-size: 1em;
  text-align: left;
  padding: 0;
  font-weight: 650;
  padding: 0.7em;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
.home-article-card:hover h3 {
  color: #ee9f02;
}
.home-article-card h3 a {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
.article-card-description {
  padding: 0.5em;
  color: #000;
}
.article-card-description p p {
  font-size: 0.95em;
  margin: 0;
  text-align: justify;
  margin-top: -1.5em;
  color: #000;
}
@media screen and (max-width: 768px) {
  .home-articles-section {
    height: auto;
    background: #fff;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }
  .home-articles-section h3 {
    text-align: center;
    font-size: 1.5em;
    font-weight: 700;
    color: #000;
    margin-top: 1em;
  }
  .home-articles-section-div {
    height: auto;
    width: 100%;
    background: #fff;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }
  .home-article-card {
    width: 100%;
    background-color: #e8f3fc;
    color: #000;
  }
  .card-image-div {
    height: 10em;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
  .card-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .home-article-card h3 {
    font-size: 1em;
    text-align: center;
    margin: 0;
    padding: 0.5em 0.5em 0 0.5em;
    font-weight: 600;
  }
  .article-card-title {
    flex-grow: 1;
  }
  .article-card-description p p {
    margin-top: 0.3em;
    font-size: 0.9em;
    margin: 0;
    padding: 0 0.3em 0 0.3em;
    text-align: justify;
  }
}
.member-info .podcast-desc-long {
  display: none;
  opacity: 0;
}
.member-info:hover .podcast-desc-long {
  animation: showlong;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  display: block;
}
@keyframes showlong {
  0% {
    opacity: 0;
    display: none;
  }
  50% {
    opacity: 0;
    display: block;
  }
  100% {
    opacity: 1;
    display: block !important;
  }
}
.member-info .podcast-desc {
  opacity: 1;
  animation: hideshort;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
}
.member-info:hover .podcast-desc {
  display: none;
}
@keyframes hideshort {
  0% {
    opacity: 0;
    display: none;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    display: block !important;
  }
}
.courses-details-main-div {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
}
.course-details-tab-div {
  width: 20%;
  display: flex;
  flex-flow: column nowrap;
  align-content: flex-start;
}
.course-details-tab-div div {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.course-details-tab-div div .tab-item {
  margin-top: 1em;
  font-size: 1.1em;
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;
  color: #000;
}
.course-details-tab-div div .active {
  border-left: 5px solid #ee9f02;
  padding-left: 1em;
  font-weight: 700;
}
.course-details-content-div {
  width: 70%;
}
@media screen and (max-width: 768px) {
  .courses-details-main-div {
    display: flex;
    flex-flow: column nowrap;
  }
  .course-details-tab-div {
    width: 85%;
    display: flex;
    flex-flow: column nowrap;
    align-content: flex-start;
    margin-bottom: 2em;
  }
  .course-details-tab-div div {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .course-details-tab-div div .tab-item {
    margin-top: 0.5em;
    font-size: 0.8em;
    cursor: pointer;
    font-weight: 400;
    text-decoration: none;
    color: #000;
  }
  .course-details-tab-div div .active {
    border-left: 5px solid #ee9f02;
    padding-left: 0.5em;
    font-weight: 600;
  }
  .course-details-content-div {
    width: 85%;
  }
}
.yt-player-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  margin-top: 1.5em;
}
.yt-player {
  height: 600px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .yt-player {
    height: 300px;
  }
}
.student-portal-button {
  height: 2.5em;
  padding: 0.1em 1em;
  border: none;
  border-radius: 50px;
  background-color: #f6b024;
  color: #fff;
  font-weight: 400;
}
.student-portal-button a {
  color: #fff;
}
.accordion {
  list-style: none;
  width: 80%;
}
.accordion_item {
  border-top: 1px solid #9f9f9f;
}
.button {
  font-size: 16px;
  background-color: #0b1632;
  color: #fff;
  text-align: left;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 18px 8px;
  cursor: pointer;
  border: none;
}
.control {
  font-size: 20px;
}
.answer {
  background-color: #f7f7f7;
  padding: 20px;
}
.accordion_item.active .button {
  background-color: #0b1632;
}
.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}
.faqs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.author-img {
  height: 3em;
  width: 3em;
  border-radius: 50%;
}
.courses-cards-div {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}


.team-member .pic img,
.team .member .pic img {
    border-radius: 50%;
    object-fit: cover; 
    margin-bottom: 10px; 
    transition: 0.3s ease-in-out; 
    height: 234px; 
    width: 15em; 
}

.modal-cta {
  
  /* padding: 1% 10%; */
  text-align: center;
  z-index: 9999;
  
}