.player_area {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 5;
}

/* overiding rhap player styling */
.rhap_container {
	background-color: #040d19;
}

.rhap_repeat-button,
.rhap_time,
.rhap_volume-button,
.rhap_main-controls-button {
	color: #cecece;
}
